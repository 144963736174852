<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>支付结算</el-breadcrumb-item>
      <el-breadcrumb-item>建行对账日志</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pkId"
            filterable
            placeholder="请选择"
            style="width: 150px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >创建时间：<el-date-picker
            v-model="inTimeBegin"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="inTimeEnd"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
            @change="inTimeEndchange"
          >
          </el-date-picker
        ></label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="orderCheckAccountLogcx"
          >查询</el-button
        >
        <el-button size="small" @click="orderCheckAccountLogcz()"
          >重置</el-button
        >
      </div>
      <!-- 点击添加显示对话框 -->
      <!-- <el-button type="primary" size="small" @click="isShowImport = true"
        >导入</el-button
      > -->
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="payOrderNo"
            label="订单号"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="checkAccountMoney"
            label="金额"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="checkAccountTime"
            label="日期"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.checkAccountTime) }}</span>
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 导入文件 -->
    <el-dialog
      title="导入文件"
      :visible.sync="isShowImport"
      width="50%"
      v-el-drag-dialog
    >
      <el-form label-width="150px" class="demo-ruleForm">
        <el-form-item label="选择导入文件">
          <el-upload
            class="upload-demo"
            ref="upload"
            :action="baseUrl"
            :auto-upload="false"
            :data="obj"
            :on-success="successChange"
            :on-progress="progressA"
            :on-change="handchange"
            :on-error="errorChange"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传xls文件，且不超过500kb
            </div>
          </el-upload>
          <el-progress
            v-show="isShowjdt"
            :percentage="percentage"
          ></el-progress>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowImport = false">取 消</el-button>
        <el-button type="primary" :loading="isShowloading" @click="ImportClick"
          >导 入</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { orderCheckAccountLog } from '@/api/common.js';
  import { findPkInfoSelect } from '@/api/Parkinglot.js';
  export default {
    data() {
      return {
        loading: false,
        isShowjdt: false,
        percentage: 0,
        isShowImport: false,
        isShowloading: false,
        isShowData: false,
        tableData: [],
        tableDatatk: [],
        fileList: [], // 上传
        inTimeBegin: undefined, // 起始
        inTimeEnd: undefined, // 截至
        query: {
          // 查询条件
          current: 1,
          size: 10,
          pkId: '', // 停车场id
          inTimeBegin: undefined, // 起始
          inTimeEnd: undefined, // 截至
        },
        obj: {
          inTime: undefined,
          pkId: undefined,
          outTime: undefined,
        },
        delId: undefined,
        total: undefined,
        baseUrl:
          process.env.VUE_APP_BASE_API +
          'parking/saas/orderInfo/uploadOrderInfo',
        PkInfoSelect: [], // 保存停车场下拉框
      };
    },
    created() {
      this.findPkInfoSelect();
    },
    methods: {
      ImportClick() {
        // 导入用户
        this.isShowloading = true;
        this.obj.inTime = this.query.inTimeBegin;
        this.obj.pkId = this.query.pkId;
        this.obj.outTime = this.query.inTimeEnd;
        this.$refs.upload.submit();
      },
      successChange(response, file, fileList) {
        // 上传
        if (response.code == 200) {
          this.$message.success('上传成功');
          this.fileList = [];
          this.orderCheckAccountLog();
        } else {
          this.$message.error(response.msg);
          this.fileList = [];
          this.orderCheckAccountLog();
        }
        this.isShowloading = false;
        this.isShowImport = false;
      },
      errorChange(err, file, fileList) {
        // 上传失败
        console.log(err);
        this.$message.error('上传失败');
      },
      progressA(event, file, fileList) {
        this.isShowjdt = true;
        setInterval(() => {
          this.percentage++;
          if (this.percentage >= 99) {
            return (this.percentage = 99);
          }
        }, 300);
      },
      handchange(file, fileList) {
        if (file.status == 'success') {
          this.percentage = 100;
          setTimeout(() => {
            this.isShowjdt = false;
          }, 500);
        }
      },
      orderCheckAccountLog() {
        // 列表
        this.query.inTimeBegin = this.inTimeBegin;
        this.query.inTimeEnd = this.inTimeEnd;
        if (this.query.inTimeBegin && !this.query.inTimeEnd) {
          return this.$message.warning('请输入截止时间');
        } else if (!this.query.inTimeBegin && this.query.inTimeEnd) {
          return this.$message.warning('请输入开始时间');
        }
        if (this.query.inTimeBegin || this.query.inTimeEnd) {
          this.query.inTimeBegin = (this.query.inTimeBegin / 1000).toFixed(0);
          this.query.inTimeEnd = (this.query.inTimeEnd / 1000).toFixed(0);
        }
        orderCheckAccountLog(this.query).then((res) => {
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
          if (this.tableData) {
            this.isShowData = true;
            this.loading = false;
          }
        });
      },
      orderCheckAccountLogcx() {
        // 查询
        this.query.current = 1;
        this.orderCheckAccountLog();
      },
      orderCheckAccountLogcz() {
        // 重置
        this.query.pkId = this.PkInfoSelect[0].id;
        this.inTimeBegin = undefined;
        this.inTimeEnd = undefined;
        this.orderCheckAccountLog();
      },
      findPkInfoSelect() {
        // 停车场下拉框
        findPkInfoSelect({ allRecord: 0 }).then((res) => {
          if (res.data.code === '200') {
            this.PkInfoSelect = res.data.data;
            this.query.pkId = this.PkInfoSelect[0].id;
            this.orderCheckAccountLog();
          }
        });
      },
      handleSizeChange(val) {
        this.loading = true;
        this.query.size = val;
        this.orderCheckAccountLog();
      },
      handleCurrentChange(val) {
        this.loading = true;
        this.query.current = val;
        this.orderCheckAccountLog();
      },
      // 结束时间选择
      inTimeEndchange() {
        if (this.inTimeBegin === null || this.inTimeBegin === undefined) {
          this.inTimeEnd = null;
          return this.$message.warning('请选择开始时间');
        } else if (this.inTimeEnd < this.inTimeBegin) {
          this.inTimeEnd = this.inTimeBegin + 3600 * 1000 * 24 * 1;
          return this.$message.warning('不可选择小于开始时间的日期');
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  ::v-deep .el-upload-list__item .el-progress__text {
    display: none !important;
  }
  ::v-deep .el-upload-list__item .el-progress {
    display: none !important;
  }
  ::v-deep .el-dialog__body {
    padding: 0 20px;
  }
</style>
